import React, { Component } from 'react'
import Layout from 'components/layout'
import Title from 'components/title'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby'

class LoggedIn extends Component {
  UNSAFE_componentWillMount() {
    if (typeof sessionStorage !== 'undefined') {
      if (!sessionStorage.getItem('loginUser')) {
        navigate('/my-account')
      }
    }
  }

  componentDidMount() {
    const signOut = document.querySelector('.signOut')
    signOut.addEventListener('click', this.logOut)
  }

  logOut() {
    sessionStorage.removeItem('loginUser')
    navigate('/')
  }

  render() {
    const data = this.props.data
    const locale = data.site.siteMetadata.locale
    const translations = data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const page = data.thisPage

    let pageHtml = page.body.childMarkdownRemark.html
    if (typeof sessionStorage !== 'undefined') {
      if (sessionStorage.getItem('loginUser')) {
        const loginUser = JSON.parse(sessionStorage.loginUser)
        if (typeof sessionStorage !== 'undefined') {
          pageHtml = pageHtml.replace('[first name]', loginUser.nameFirst)
          pageHtml = pageHtml.replace('[email]', loginUser.email)
        }
      }
    }

    return (
      <Layout>
        <div id="content" className="page-template-page-account">
          <Title title={page.title} />
          <section className="page-content wrap center">
            <div
              dangerouslySetInnerHTML={{
                __html: pageHtml,
              }}
            />
          </section>
        </div>
      </Layout>
    )
  }
}

export default LoggedIn

export const loggedIn = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "logged-in" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
